import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/about-img5.png'
import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
 
const AboutUsContent = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    Chi siamo
                                </span>

                                <h2>The Digital Transformation for the Sustainable Economy</h2>      
                                <p>Technology is one of the drivers of the change our world is going through. If we want to build a more equitable and sustainable world, it's important to use technology, to drive rather than suffer change.
</p>                          
                                <ul className="features-list">
                                    <li>
                                        <img src={icon4} alt="banner" />
                                        <h3>10 years</h3>
                                        <p>on the market</p>
                                    </li>
                                    <li>
                                        <img src={icon5} alt="banner" />
                                        <h3>10+</h3>
                                        <p>Team members</p>
                                    </li>
                                    <li>
                                        <img src={icon6} alt="banner" />
                                        <h3>100%</h3>
                                        <p>Technical support</p>
                                    </li>
                                    <li>
                                        <img src={icon7} alt="banner" />
                                        <h3>100%</h3>
                                        <p>Innovation</p>
                                    </li>
                                </ul>
                                <p>Technological innovation is not a goal in itself, but a tool for development</p>                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our vision</h3>
                                <p>Our vision is for a world where technology is universally accessible and used to improve people's lives</p>
                                
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our Mission</h3>
                            <p>
                            We make the latest technology accessible to NGOs, third sector entities, cooperatives and social economy actors. We create wide-ranging partnerships that focus on sustainable development goals and allow us to accompany our partners through consulting, development of ad-hoc solutions and training. 	    			</p>    
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="about-text">
                                <h3>Guiding principles</h3>
                        <p>
                        Innovation, quality and efficiency are necessary elements for the development of technology and services that we provide through our work. The constant support to our partners and customers, from planning to project development, contributes to the successful achievement of their goals. Motivation, ability to work in a team and willingness to take responsibility are a guarantee for our successful performance. Openness, flexibility, mutual support and the pleasure of working for a common goal identify our company.
                        </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>
        </section>
    )
}

export default AboutUsContent;
