import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import AboutUsContent from '../components/AboutUs/AboutUsContent'
import TeamMembers from '../components/AboutUs/TeamMembers'
import Testimonials from '../components/AboutUs/Testimonials'

 
const AboutUs = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="About us" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="About us" 
            />
            <AboutUsContent />
            <TeamMembers />
            <Testimonials />
            <Footer />
        </Layout>
    );
}

export default AboutUs;
