import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import tulas from '../../assets/images/team/tulas2.jpg'
import giudi from '../../assets/images/team/giudi2.jpg'
import trik from '../../assets/images/team/trik2.jpg'
import peppe from '../../assets/images/team/peppe2.jpg'
import sara from '../../assets/images/team/sara2.jpg'
import rob from '../../assets/images/team/rob2.jpg'
import paolo from '../../assets/images/team/paolo2.jpg'
import marco from '../../assets/images/team/marco2.jpg'
import gala from '../../assets/images/team/gala2.jpg'
import chiara from '../../assets/images/team/chiara.jpg'
import lucia from '../../assets/images/team/lucia.jpeg'
import niko from '../../assets/images/team/niko.jpg'

const TeamMembers = () => {
    return (
        <section className="scientist-area bg-color pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                        Team
                    </span>
                    <h2>Our Co-operators</h2>
                    <p>A heterogeneous and prepared team to support your projects</p>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={tulas} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Andrea Bertolazzi</h3>
                                <span>President and Business developer</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={giudi} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Giuditta Caimi</h3>
                                <span>Vice President and Project manager</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={marco} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Marco Tozzi</h3>
                                <span>Fullstack developer and Co-op member</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={sara} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Sara Repossi</h3>
                                <span>Fullstack developer</span>
                            </div>
                        </div>
                    </div>
                                        <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={rob} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Roberto Zanotto</h3>
                                <span>Web developer and Co-op member</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={paolo} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Paolo Palmerini</h3>
                                <span>Project Manager and Board member</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={gala} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Gala Brezigar</h3>
                                <span>UI/UX designer</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={lucia} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Lucia Dal Negro</h3>
                                <span>Board member</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={trik} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Marco Marche</h3>
                                <span>Co-op member</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={peppe} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Giuseppe Bonfanti</h3>
                                <span>Co-op member</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={niko} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Nikolai Polikurov</h3>
                                <span>Co-op member</span>
                            </div>
                        </div>
                    </div>


			
                </div>
            </div>
        </section>
    )
}

export default TeamMembers
