import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import unhcr from '../../assets/images/testimonials/unhcr.png'
import ciai from '../../assets/images/testimonials/ciai.png'
import stc from '../../assets/images/testimonials/stc.png'
import cuamm from '../../assets/images/testimonials/cuamm.png'
import coopi from '../../assets/images/testimonials/coopi.png'
import cbm from '../../assets/images/testimonials/cbm.png'
import acted from '../../assets/images/testimonials/acted.png'
import helpcode from '../../assets/images/testimonials/helpcode.png'
import manitese from '../../assets/images/testimonials/manitese.png'
import ocdc from '../../assets/images/testimonials/ocdc.png'
import legacoop from '../../assets/images/testimonials/legacoop.png'
import wwgvc from '../../assets/images/testimonials/wwgvc.png'
import acra from '../../assets/images/testimonials/acra.png'
import ixs from '../../assets/images/testimonials/ixs.png'
import ccb from '../../assets/images/testimonials/ccb.png'
import ibva from '../../assets/images/testimonials/ibva.png'
import cl from '../../assets/images/testimonials/cl.jpg'
import isforcoop from '../../assets/images/testimonials/isforcoop.jpg'
import soleterre from '../../assets/images/testimonials/soleterre.png'
import pico from '../../assets/images/testimonials/pico.png'
import cri from '../../assets/images/testimonials/cri.png'


import shape from '../../assets/images/shape/shape1.svg'

import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ],
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        992: {
            items: 2,
        }
    }
};

const Testimonials = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <section className="testimonials-area bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                        Our clients
                    </span>
                    <h2>Who are our clients?</h2>
                    <p>Since 10 years at the service of Third Sector entities, NGOs, social enterprises and cooperatives.
</p>
                </div>

                {display ? <OwlCarousel 
                    className="testimonials-slides owl-carousel owl-theme"
                    {...options}
                > 
                    <div className="single-testimonials-item">
                        <p>Gnucoop started its collaboration with UNHCR to respond to their need for troubleshooting, bug-fixing and maintaining their Twine web platform.
After this phase, Gnucoop was granted the commission to re-design the application to make it more suitable to current and future needs, overcoming the limitations of the original application.</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={unhcr} alt="about" />
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
                        <p>Our collaboration with Save the Children Italia began at the end of 2018 when we were contacted to evaluate the possibility of a partnership for participation in the AICS OSC call for proposals. </p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={stc} alt="about" />
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
                        <p>CIAI is an Italian NGO working for the rights of children in Italy and abroad. We started our collaboration with CIAI back in 2009, providing support for the design and implementation of the internal ICT infrastructure of the organisation.  </p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={ciai} alt="about" />
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
                        <p>We met CUAMM during a training session on eHealth at the beginning of 2019. We started discussing about their need to improve the way they were collecting and managing data coming from their health projects.  </p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={cuamm} alt="about" />
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
                        <p>The non-IT component of our staff, has taken the first steps in International Cooperation with the NGO COOPI - Cooperazione Internazionale. Also the first Gnucoop projects were carried out in collaboration with COOPI, in South East African Region and Haiti. 
</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={coopi} alt="about" />
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
                        <p>We won a tender published by the NGO ACTED for the development of a web and mobile application for the monitoring of all phases necessary for water distribution activities. </p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={acted} alt="about" />
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
                        <p>Helpcode is an organisation operating in Italy and around the world to ensure that every boy and girl has the protection of a family, a quality school, and a community where they can grow up and express their potential.  
</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={helpcode} alt="about" />
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
                        <p>CBM is an international Christian development organisation, committed to improving the quality of life of persons with disabilities in the poorest communities of the world.</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={cbm} alt="about" />
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
                        <p>Gnucoop began its collaboration with the Italian Red Cross (CRI) in May 2022 as part of the project- RELIEF-whose goal is to strengthen CRI's capacity to respond to the needs of populations affected by natural or man-made disasters through vulnerability assessment in potential areas of intervention, monitoring of interventions, and management of the distribution of goods and services.
</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={cri} alt="about" />
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
                        <p>Mani Tese is a non-governmental organization that has been fighting for social, economic and environmental justice in the world for over fifty years.</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={manitese} alt="about" />
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
                        <p>OCDC brings together eight organizations committed to building a more prosperous world through cooperatives. Its mission is to champion, advocate and promote effective international cooperative development. Working as one, we promote sustainability and self-reliance through local ownership.</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={ocdc} alt="about" />
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
                        <p>"To support in the most dynamic and effective way the economic, social and civil protagonism of cooperative enterprises". For 125 years this has been the objective of Legacoop, the association that today brings together more than 10 thousand cooperative enterprises, active in all regions and sectors to create development, focusing on people and territory.</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={legacoop} alt="about" />
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
                        <p>WeWorld-GVC is an Italian independent organisation born from the merger between GVC Ngo (founded in Bologna in 1971) and WeWorld Foundation (founded in Milan in 1999), with the aim of increasing the impact of their Development Cooperation and Humanitarian Aid projects in 29 countries worldwide, including Italy.
</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={wwgvc} alt="about" />
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
                        <p>ACRA is a non-governmental, secular and independent organization, founded in Milan in 1968. For 50 years it has been working to fight poverty through sustainable, innovative, and participatory solutions, to foster a growing, sustainable development and more global citizenship. </p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={acra} alt="about" />
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
                        <p>A strategic and multidisciplinary project on international cooperation and development aiming at fostering technological innovation processes and products in order to get creative responses to the most pressing problems that low-income countries population face.</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={ixs} alt="about" />
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
                        <p>Consorzio Comunità Brianza is a consortium of social cooperatives that promotes initiatives and projects in the social field, participates in national projects, manages and provides, both directly and through the consortium member cooperatives, sociomedical and educational services for public and private clients.
</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={ccb} alt="about" />
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
                        <p>iBVA - Istituto Beata Vergine Addolorata is a lay non-profit association, founded in 1801, which works in favour of people in difficulty, both Italian and foreign, without discrimination in terms of census, religion and geographical origin.
</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={ibva} alt="about" />
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
			<p>
			The collaboration with PICO stems from the entity's need to have a tool through which to manage a call for proposals aimed at promoting and encouraging the growth, modernization and development of Italian cooperatives and nonprofits through innovation and digital transition.
			</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={pico} alt="about" />
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
			<p>
			Our collaboration with Soleterre began at the end of 2021. We created a data collection platform to measure the organization's indicators, starting with an internal monitoring and evaluation system to measure its strategies, which, more recently, have been structured through an internal ToC.

			</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={soleterre} alt="about" />
                            </div>
                        </div>
                    </div>
                    <div className="single-testimonials-item">
                        <p>ISFORCOOP is the training agency of Legacoop Sardegna, which has been working for over thirty years in the field of planning and implementation of training interventions related to the professional qualification of workers and the updating of human capital in companies, with particular attention to the companies belonging to the cooperative system. </p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={isforcoop} alt="about" />
                            </div>
                        </div>
                    </div>
                </OwlCarousel> : ''}

            </div>

            <div className="shape-img1">
                <img src={shape} alt="about" />
            </div>
        </section>
    )
}

export default Testimonials
